import { animated, useTransition } from 'react-spring'
import { useMediaQuery } from '@kaliber/use-media-query'

import mediaStyles from '/cssGlobal/media.css'
import styles from './FloatingImage.css'

export function FloatingImage({
  children,
  endPosition: { x, y },
  animation,
  indexInGroup,
  isActive = false,
  isFirstGroup = false,
  layoutClassName = undefined
}) {
  const isViewportMd = useMediaQuery(mediaStyles.viewportMd)
  const isViewportXl = useMediaQuery(mediaStyles.viewportXl)

  const baseScale = isViewportMd ? 1 : 0.85
  const modifier = (isFirstGroup && !isViewportMd) ? 1.5 : 1

  const transition = useTransition(isActive, {
    config: (_, i, state) => ({ duration: isFirstGroup ? 150 : (state === 'leave' ? 350 : 1000) }),
    from: { opacity: 0 },
    enter: { opacity: animation.value.to(x => x + 0.25), delay: 100 + (indexInGroup * (isFirstGroup ? 0 : 100)) },
    leave: { opacity: 0 },
  })

  const visibility = animation.value.to(reasonVisibility)
  const transform = animation.value.to(calculateTransform)

  return (
    transition(({ opacity }, isVisible) => (
      isVisible && (
        <animated.div
          className={cx(styles.component, layoutClassName)}
          style={{ opacity, visibility, transform }}
        >
          <animated.span
            className={styles.inner}
            style={{ transform: animation.value.to(x => `scale(${x * modifier / baseScale})`) }}
          >
            {children}
          </animated.span>
        </animated.div>
      )
    ))
  )

  function calculateTransform(value) {
    const yUnit = isViewportMd ? 1 : 0.9
    const xUnit = isViewportXl
      ? 0.75
      : isViewportMd
        ? 1
        : 0.9

    return `translate(
      calc(${(value * x) + ((1 - value) * (Math.sign(x) * 5))} * ${xUnit * modifier}vw),
      calc(${(value * y) + ((1 - value) * (Math.sign(y) * 5))} * ${yUnit * modifier}svh)
    )`
  }
}

function reasonVisibility(value) {
  return (value >= 1.5) ? 'hidden' : 'visible'
}
