import { useSpring, animated, useTransition, useSprings, useScroll, config, useSpringValue } from 'react-spring'
import { useMediaQuery } from '@kaliber/use-media-query'
import { sequence } from '@kaliber/math'
import { easeInQuart } from '/machinery/easings'
import { useTranslate } from '/machinery/I18n'

import { Button, ButtonGhost } from '/features/buildingBlocks/Button'
import { Image } from '/features/buildingBlocks/Image'
import { VideoExternalControls } from '/features/buildingBlocks/Video'
import { HeadingLg, HeadingSm } from '/features/buildingBlocks/Heading'
import { FloatingImage } from '/features/pageOnly/workWithHeartAndSoul/FloatingImage'
import { FloatingVideo } from '/features/pageOnly/workWithHeartAndSoul/FloatingVideo'
import { useLocationMatch } from '@kaliber/routing'
import { routeMap } from '/routeMap'

import mediaStyles from '/cssGlobal/media.css'
import styles from './Scroller.css'

export function Scroller({ depthPerFrame, images, text, hero }) {
  const [visibleId, setVisibleId] = React.useState(0)
  const scrollRef = React.useRef(null)

  const frames = sequence(images.length)
  const frameCount = frames.length
  const textCount = text.length
  const scrollHeight = textCount * depthPerFrame

  const { animations: timeline, scrollYProgress } = useComponentScroll({
    onIdChange: setVisibleId,
    imageCount: frameCount,
    textCount,
    scrollRef,
  })

  const [videoTimelineAnimation, ...timelineAnimations] = timeline

  return (
    <div
      ref={scrollRef}
      className={cx(styles.component_root, styles.component)}
      style={{ '--scroller-height': scrollHeight }}
    >
      <FrameAnimator
        animations={timelineAnimations}
        layoutClassName={styles.animatorLayout}
        {...{ images, visibleId, textCount }}
      />

      <div className={styles.overlay}>
        <TextAnimator layoutClassName={styles.titleLayout} {...{ visibleId, text }} />
        <ProgressCircle count={textCount} current={visibleId} layoutClassName={styles.progressLayout} progress={scrollYProgress} />
        <ScrollHint isVisible={visibleId === 0} layoutClassName={styles.scrollHintLayout} />
      </div>

      <FloatingVideo
        isActive={visibleId >= 3}
        animation={videoTimelineAnimation}
        layoutClassName={styles.floatingVideoLayout}
      >
        <div className={styles.videoContainer}>
          {hero && (
            <Video
              url={hero.url}
              title={hero.title}
              poster={hero.poster}
              posterSizes={`100vw`}
              animation={videoTimelineAnimation}
              layoutClassName={styles.videoLayout}
              {...{ depthPerFrame, textCount }}
            />
          )}
        </div>
      </FloatingVideo>
    </div>
  )
}

function ScrollHint({ isVisible, layoutClassName }) {
  const { __ } = useTranslate()

  const repeat = useSpring({
    config: { mass: 5, friction: 50, damping: 100 },
    from: { opacity: 0, y: 20, transform: 'scale(0.95)' },
    to: {
      opacity: isVisible ? 1 : 0,
      y: isVisible ? 10 : 0,
      transform: isVisible ? 'scale(1)' : 'scale(0.95)'
    },
    delay: 1000
  })

  return (
    <animated.div style={{ ...repeat }} className={layoutClassName}>
      {__`scroll-to-explore`}
    </animated.div>
  )
}

function Video({
  url,
  title,
  poster,
  animation,
  textCount,
  depthPerFrame,
  posterSizes = undefined,
  layoutClassName = undefined
}) {
  const [isPlaying, setIsPlaying] = React.useState(true)
  const videoCoverRef = React.useRef(null)

  const { params: { language } } = useLocationMatch()
  const { __ } = useTranslate()

  const usedPlayFromStartButton = useSpringValue(1, { config: config.slow })

  return (
    <div className={cx(styles.componentVideo, layoutClassName)}>
      <div className={styles.fullScreenElement}>
        <div className={styles.videoWrapper}>
          <VideoExternalControls
            ref={videoCoverRef}
            onPlayChange={x => x !== isPlaying && setIsPlaying(x)}
            muted
            layoutClassName={styles.videoCoverLayout}
            {...{ isPlaying, title, poster, url, posterSizes }}
          />
        </div>
      </div>

      <animated.div
        className={styles.buttons}
        style={{
          opacity: animation.value.to(easeInQuart),
        }}
      >
        <Button
          onClick={handleClick}
          dataX='click-to-play-video'
          layoutClassName={styles.buttonLayout}
        >
          {__`play-video-from-start-with-audio`}
        </Button>
        <ButtonGhost
          dataX='link-to-jobsOverview'
          href={routeMap.app.jobs.overview({ language })}
        >
          {__`come-work-with-us`}
        </ButtonGhost>
      </animated.div>
    </div>
  )

  function handleClick() {
    window.scrollBy({
      top: window.innerHeight * (depthPerFrame / 100) * textCount,
      behavior: 'smooth',
      left: 0,
    })

    usedPlayFromStartButton.start(0)
    videoCoverRef.current.playFromStart()
    videoCoverRef.current.unmute()
    videoCoverRef.current.muted = false
    setIsPlaying(true)
  }
}

function TextAnimator({ visibleId, text, layoutClassName }) {
  const isViewportMd = useMediaQuery(mediaStyles.viewportMd)

  const titleTransition = useTransition(visibleId, {
    from: { opacity: 0, y: 5, transform: 'scale(0.85)' },
    enter: { opacity: 1, y: 0, transform: 'scale(1)', delay: 500 },
    leave: { opacity: 0, y: -5, transform: 'scale(1.05)' },
    config: (index, item, phase) => {
      return phase === 'enter'
        ? config.slow
        : {}
    }
  })

  const Component = isViewportMd
    ? HeadingLg
    : HeadingSm

  return titleTransition((animation, id) => (
    <div className={cx(styles.componentTextAnimator, layoutClassName)}>
      <Component h={1} title={text[id]} {...{ animation }} />
    </div>
  ))
}

function useComponentScroll({ scrollRef, textCount, imageCount, onIdChange }) {
  const offset = 1
  const count = textCount + offset
  const groupSize = Math.ceil(imageCount / textCount)

  const [animations, springApi] = useSprings(imageCount + 1, () => ({
    value: 0
  }))

  const { scrollYProgress } = useScroll({
    container: scrollRef.current,
    config: { mass: 1, tension: 210, friction: 20 },
    onChange: ({ value }) => {
      const id = getId({ value })

      onIdChange(id)

      springApi.start(i => {
        const minElementStartingPosition = 0.35
        const maxElementStartingPosition = 0.8
        const distanceBetweenGroups = (maxElementStartingPosition - minElementStartingPosition) / groupSize

        const groupIndex = Math.floor(i / textCount)
        const indexInGroup = i - (groupIndex * textCount)

        const distanceBetweenItemsInGroup = 0.025
        const offsetInGroup = indexInGroup * distanceBetweenItemsInGroup
        const input = easeInQuart((distanceBetweenGroups * groupIndex) + minElementStartingPosition + offsetInGroup + value.scrollYProgress)

        return {
          value: input
        }
      })
    }
  })

  return {
    animations,
    scrollYProgress
  }

  function getId({ value }) {
    const currentId = Math.floor(value.scrollYProgress * count)
    const upperLimit = Math.min(currentId, count - 1)
    const lowerLimit = 0

    return Math.max(lowerLimit, upperLimit)
  }
}

function FrameAnimator({ images, animations, visibleId, textCount, layoutClassName }) {
  const groupCount = Math.ceil(images.length / textCount)
  const positions = useSpiral({ groupCount, imageCount: images.length })

  return (
    <div className={cx(styles.componentFrameAnimator, layoutClassName)}>
      {images.map((image, i) => {
        const groupIndex = Math.floor(i / textCount)
        const indexInGroup = i - (groupIndex * textCount)

        return (
          <FloatingImage
            key={i}
            endPosition={positions[i]}
            isFirstGroup={groupIndex === 2}
            layoutClassName={styles.floatingImageLayout}
            isActive={groupIndex === (textCount - (visibleId + 2))}
            animation={animations[i]}
            {...{ indexInGroup }}
          >
            <Image {...{ image }} />
          </FloatingImage>
        )
      }
      )}
    </div>
  )
}

function ProgressCircle({ current, count, progress, layoutClassName }) {
  const { opacity } = useSpring({
    opacity: current < count - 1 ? 1 : 0
  })

  return (
    <animated.div
      className={cx(styles.componentProgressCircle, layoutClassName)}
      style={{
        '--progress': progress.to(x => (x * 1.5) * 100),
        '--progress-count': Math.min(current + 1, count),
        '--progress-total': count,
        opacity
      }}
    >
      <animated.progress
        className={styles.progress}
        value={100 / (count / current)}
        max={100}
      />
    </animated.div>
  )
}

function generateSpiralPoints({ groupCount, imageCount, offset, radius }) {
  const points = []

  for (let i = 0; i < imageCount; i++) {
    const deltaAngle = (Math.PI * 2) / imageCount
    const angle = offset + i * deltaAngle * groupCount

    const x = radius * (Math.cos(angle) + (Math.sign(Math.cos(angle)) * 0.5))
    const y = radius * (Math.sin(angle) + (Math.sign(Math.sin(angle)) * 0.25))

    points.push({ x, y })
  }

  return points
}

function useSpiral({ groupCount, imageCount }) {
  const isViewportMd = useMediaQuery(mediaStyles.viewportMd)
  const radius = isViewportMd ? 50 : 35
  const offset = isViewportMd ? 0 : 100

  const spiralRef = React.useRef(generateSpiralPoints({
    groupCount,
    imageCount,
    offset,
    radius
  }))

  return spiralRef.current
}
