import { animated, useSpring } from 'react-spring'

import styles from './FloatingVideo.css'

export function FloatingVideo({
  children,
  isActive,
  animation,
  layoutClassName = undefined
}) {
  const { opacity } = useSpring({
    config: { duration: 1000 },
    opacity: isActive ? animation.value.to(x => x + 0.5) : 0,
  })

  const innerTransform = animation.value.to(calculateInnerTransform)

  return (
    <animated.div
      className={layoutClassName}
      style={{ opacity, pointerEvents: isActive ? 'auto' : 'none' }}
    >
      <animated.span className={styles.wrapper} style={{ transform: innerTransform }}>
        {children}
      </animated.span>
    </animated.div>
  )

  function calculateInnerTransform(value) {
    return `scale(${Math.min(1, value)})`
  }
}
